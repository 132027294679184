@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.half-blue-half-yellow-shadow {
  box-shadow: 3px 6px 7px -4px #fff41c, -1px 4px 5px 1px #1739b6;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #6b7280;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #1f2937;
  border-radius: 4px;
}

body {
  /* fix for iOS */
  background-color: #06080b;
  margin: 0;
  padding: 0;
}

@layer base {
  html {
    font-family: "Raleway", sans-serif;
  }

  /* Style for the custom scrollbar */
  .scrollbar-dark::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  .scrollbar-dark::-webkit-scrollbar-thumb {
    background-color: #6b7280; /* Thumb color */
    border-radius: 4px;
  }

  .scrollbar-dark::-webkit-scrollbar-track {
    background-color: #1f2937; /* Track color */
    border-radius: 4px;
  }
}
